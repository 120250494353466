section#profile {
    border-top: #c1e4e9 2px solid;
    border-bottom: #c1e4e9 2px solid;
    background-color: #eaf4fc;
}
section#profile div.profile_image {
    margin-top: 24px;
    margin-bottom: 24px;
}
section#profile div.profile_image div.illustration {
    margin: 0 auto;
    width: 256px;
    height: 256px;
    border: #c1e4e9 2px solid;
    border-radius: 128px;
    background-image: url('./profile_image.jpg');
    background-repeat: no-repeat;
    background-size: contain;
}
@media ( min-width : 823px ){
    section#profile .flex {
        display: -webkit-flex;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-content: flex-start;
    }
    section#profile .flex div.profile_image {
        flex: 308px 308px 308px; /* width + border * 2 + margin * 2 */
        margin: 24px;
    }
    section#profile .flex div.profile_comment {
        flex: auto;
    }
}
