section#works {

}
section#works div.works {

}
section#works div.work {
    border-color: #bbbbbb;
}
section#works div.work h2 {
    font-weight: bold;
    margin: 6px 0;
}
section#works div.work h2 a {
    font-weight: bold;
}
section#works div.work h2 a:hover {
    text-decoration: underline;
}
section#works div.work div.tech {
    font-size: 12px;
}