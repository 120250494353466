section#blog {
    border-top: #e4e9c1 2px solid;
    border-bottom: #e4e9c1 2px solid;
    background-color: #f4fcea;
}
section#blog div.articles {

}
section#blog div.article {
    position: relative;
    padding-bottom: 24px;
    border-color: #e4e9c1;
    text-align: left;
    background-color: #ffffff;
}
section#blog div.article div.article_title {
    /* elipsys */
}
section#blog div.article div.article_date {
    position: absolute;
    bottom: 3px;
    right: 3px;
    width: 100%;
    font-size: 12px;
    text-align: right;
}